.members_page {
  .wrapper {
    width: 100%;
    padding-bottom: 13vh;
    .contents {
      width: 80%;
      max-width: 840px;
      margin: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      h1 {
        font-size: 1.2em;
        width: 100%; }
      #list_member {
        margin: 3%;
        text-align: center; } } } }
