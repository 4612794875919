@import "./common/common.sass";

.result {
  width: 100%;
  max-width: 420px;
  margin: 10%;
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    width: 100%;
    font-size: 1.2em;
    border-bottom: 2px solid $basic_underline; }
  ul {
    width: 100%;
    list-style: none;
    padding: 0px;
    margin-left: 0.7em;
    li {
      margin-bottom: 0.5em; } }
  .result_take {
    width: 100%;
    margin-bottom: 50px; }
  .result_give {
    width: 100%; }
  .triptitle {
    width: 60%;
    border-bottom: 2px solid $basic_underline;
    text-align: center;
    white-space: nowrap; } }
