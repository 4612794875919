@import "./common/common";

.board__item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: $basic_fontfamily;
  border-bottom: 2px solid $basic_underline;
  padding-bottom: 10px;
  .item__title {
    font-size: 1.1em;
    width: 100%;
    margin-bottom: 10px;
    text-align: center; }
  .item__time {
    font-size: 0.8em;
    display: flex;
    flex-direction: row;
    justify-content: space-around; } }
