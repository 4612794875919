/* Button design */
.button_float {
  display: inline-block;
  font-size: 1em;
  color: #FFF;
  text-decoration: none;
  background: #ffc107;
  border: none;
  border-bottom: 4px solid #627295;
  border-radius: 20px;
  outline: none;
  height: 2em;
  width: 5em;
  padding: 0px;
  font-family: "M PLUS Rounded 1c"; }

// Colors
.warning {
  background: #ffc107; }

// Sizes
.small {
  width: 3em;
  font-size: 0.9em; }
.large {
  height: 2.5em;
  width: 8em; }
