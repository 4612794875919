@import "./common/common";

form {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  height: 2.4em;

  input {
    font-size: 1em;
    width: 20%;
    border: none;
    border-bottom: 2px solid $basic_underline;
    border-radius: 0px;
    outline: none;
    height: 100%;
    padding: 0px;
    font-family: $basic_fontfamily; }

  .name {
    width: 35%;
    padding-left: 0%; }
  .price {
    width: 15%;
    padding-right: 1%;
    margin-left: 3%;
    text-align: right; }
  .paidby {
    width: 25%;
    margin-left: 3%; }
  .button_area {
    width: 15%;
    margin-left: 3%; }
  .form_select-unmatch {
    color: #aaa; }
  select {
    height: 100%;
    padding-bottom: 2px;
    font-size: 1em;
    border: none;
    border-bottom: 2px solid $basic_underline;
    border-radius: 0px;
    background-color: transparent;
    font-family: $basic_fontfamily;

    .form__select--placeholder {
      display: none; } } }
