@import "./common/common";

#boardlistpage {
  .content {
    margin-top: 15%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    &__title {
      width: 80%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 5%;
      h1 {
        width: auto; } }
    #list_board {
      display: flex;
      flex-direction: column;
      justify-content: center; } } }
