/* Button design */
button {
  display: inline-block;
  font-size: 1em;
  color: #FFF;
  text-decoration: none;
  background: #668ad8;
  border: none;
  border-bottom: 4px solid #627295;
  border-radius: 3px;
  outline: none;
  height: 2em;
  width: 5em;
  padding: 0px;
  font-family: "M PLUS Rounded 1c";
  &:active {
    transform: translateY(3px)/*Push movement*/;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.2)/*Smaller shadow*/;
    padding-bottom: 4px;
    border-bottom: none; } }

// Colors
.warning {
  background: #ffc107; }

// Sizes
.small {
  width: 3em;
  font-size: 0.9em; }
.midium {
  height: 2.5em;
  width: 8em;
  font-size: 0.9em; }
.large {
  height: 2.5em;
  width: 8em; }
