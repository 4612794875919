@import "./common/common";

.wrapper {
  height: 100%;
  padding-bottom: 15vh;
  display: flex;
  justify-content: center; }

#list_receipt {
  width: 100%;
  max-width: 840px;
  margin: 3%;
  margin-top: 9vh;
  text-align: center;
  h1 {
    margin: 0px; }
  .title {
    width: 50%;
    text-align: center; } }

.float_area_top {
  max-width: 840px;
  width: 100%;
  height: 7vh;
  top: 50px;
  position: fixed;
  background-color: rgba(255,255,249,1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 9px 10px 0px rgba(255,255,249,1);
  form {
    margin: 0px; } }

.float_area {
  max-width: 840px;
  width: 100%;
  height: 7vh;
  bottom: 8vh;
  position: fixed;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px -10px 10px 0px rgba(255,255,255,0.8);
  &--left {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-left: 3%; }
  &--right {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 4%;
    .icons {
      display: flex;
      .slidein_icons {
        z-index: -1;
        .copy_button .icon {
          width: 50%; }
        .line_button--show {
          position: absolute;
          animation: slidein__line 0.5s ease-in-out forwards; }
        .line_button--hide {
          position: absolute;
          animation: slidein__line--reverse 0.5s ease-in-out forwards; }
        .copy_button--show {
          position: absolute;
          animation: slidein__copy 0.5s ease-in-out forwards;
          z-index: -2; }
        .copy_button--hide {
          position: absolute;
          animation: slidein__copy--reverse 0.5s ease-in-out forwards;
          z-index: -2; } } } } }



// Popups for instruction
.comment-popup {
  font-size: 1.2em;
  color: gray;
  padding: 10px;
  position: absolute;
  top: -3em;
  background-color: white;
  border: 1px solid gray;
  border-radius: 15px;
  opacity: 0;
  animation: slowPopup 1s ease-in-out 1s 1 normal forwards, shake 1s ease-in-out 2s infinite; }


// Shows share icons when share button is pressed
@keyframes slidein__line {
  from {
    margin-right: 4%;
    right: 0px; }
  to {
    right: clamp(65px, 10vw, 90px); } }

@keyframes slidein__line--reverse {
  from {
    right: clamp(65px, 10vw, 90px); }
  to {
    margin-right: 4%;
    right: 0px; } }

@keyframes slidein__copy {
  from {
    margin-right: 4%;
    right: 0px; }
  to {
    right: clamp(110px, 15vw, 140px); } }

@keyframes slidein__copy--reverse {
  from {
    right: clamp(110px, 15vw, 140px); }
  to {
    margin-right: 4%;
    right: 0px; } }

// shows popup slowly
@keyframes slowPopup {
  from {
    opacity: 0;
    top: -2em; }
  to {
    opacity: 1;
    top: -3em; } }

// shakes popup to emphasize itself
@keyframes shake {
  0% {
    transform: rotate(0deg); }
  30% {
    transform: rotate(-5deg); }
  60% {
    transform: rotate(5deg); }
  100% {
    transform: rotate(0deg); } }
