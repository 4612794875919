@import "./common/common";

.app {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #34241e;
  background-color: $basic_background;
  font-family: $basic_fontfamily;
  &__contents {
    margin-top: 50px;
    width: 100%;
    height: 100%;
    input {
      background-color: $basic_background; } } }
