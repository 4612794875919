// Colors
$basic_fontfamily: "M PLUS Rounded 1c";
$basic_background: #fffff9;
$basic_underline: gray;

// Basics
.line_button {
  display: inline-block; }

.share_button, .copy_button {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: lightgray;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  .icon {
    width: 60%;
    height: 60%;
    color: gray; } }



a:link {
  color: inherit;
  text-decoration: none; }

a:visited {
  color: inherit;
  text-decoration: none; }

a:hover {
  color: inherit;
  text-decoration: none; }

a:active {
  color: inherit;
  text-decoration: none; }


h1 {
  font-size: 1.2em;
  width: 100%; }
