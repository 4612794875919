@import "./common/common";

.header {
  height: 50px;
  width: 100%;
  color: white;
  background-color: #3eb0ff;
  position: fixed;
  text-align: center;
  font-weight: bold;
  font-family: $basic_fontfamily;
  .content__area {
    height: 100%;
    width: 92%;
    margin: 0px 4%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    .content {
      font-size: 1.8em; }
    .title {
      font-size: 1.2em;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 1.1em;
        margin-right: 5px; } }
    .empty {
      width: 1.4em; } } }



.popup-content {
  color: dimgray;
  width: auto;
  min-width: 55%;
  margin: auto;
  background: rgb(255, 255, 255);
  padding: 5px;
  background: white;
  border-radius: 5px;
  font-family: $basic_fontfamily;
  font-size: 0.6em;
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  ul {
    width: 86%;
    margin: 7%;
    padding: 0px;
    display: flex;
    flex-direction: column;
    li {
      width: 100%;
      margin-bottom: 10px;
      list-style: none;
      cursor: pointer; }
    .popup__item {
      &-underline {
        border-bottom: 1px solid lightgray; }
      &-empty {
        height: 1em; }
      .login_button {
        max-width: 200px;
        margin: 0px;
        display: flex;
        justify-content: flex-start;
        .google_logo {
          width: 100%; } } }
    .popup__title {
      font-size: 1.1em;
      height: 2em;
      border-bottom: 1px solid lightgray;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start; }
    .popup__icon {
      color: gray;
      width: 1.5em;
      height: 1.5em;
      margin-right: 0.5em; } } }

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; }
  50% {
    transform: scale(1) translateY(0px);
    opacity: 1;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px; } }
