@import "./common/common";

.landing {
  display: flex;
  justify-content: center;
  .contents {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 840px;
    &__main {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      img {
        width: 80%;
        &--title {
          width: 80%; } } }
    &__sub {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: 15%;
      h1 {
        border-bottom: 2px solid $basic_underline; }
      .description--spacetop {
        margin-bottom: 0px; }
      .description--nospace {
        margin: 0px; }
      img {
        width: 100%; }
      .image--small {
        width: 60%; }
      .image--smaller {
        width: 55%;
        margin-top: 5%; }
      .border {
        border: 1px solid darkgray; }
      .margin-right {
        margin-right: 5%; } }
    &__row {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-top: 15%; }
    .image__screen {
      margin-top: 10%; }
    &__margin {
      width: 100%;
      margin-top: 15%; }
    &__margin--large {
      width: 100%;
      margin-top: 40%; }
    footer {
      font-size: 0.8em;
      padding-bottom: 57px; } } }
