#footer {
  background-color: white;
  height: 8vh;
  width: 100%;
  max-height: 60px;
  border-top: 1px solid black;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  bottom: 0px;
  position: fixed;
  .footer__contents {
    width: 90%;
    max-width: 840px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 5%;
    .footer__prices {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      #footer__price--total {
        font-size: 1.2em; }
      #footer__price--average {
        font-size: 0.8em; } } } }
